export const projectViewConstants = {
    splitFormConstants: {
        title: "Split Form",
        error: "! Please Fill Split Form",
    },
    reviseForm: {
        title: "Revise Amount",
        error: "! Please Fill Revise Form",
    },
    addRemarks: {
        title: "Add Remarks",
        error: "! Please Fill remark Form",
    },
    ummalKagaz: "Ummal Kagaz",
    downloadReport: "Download Report",
    remarks: "Remarks",
    total: "Total Amount",
    splitAmount: {
        title: "Split Amount",
        sources: "Sources",
    },
    qardan: {
        qardan: "Qardan Hasanah",
        totalQardan: "Total QA Amount",
        repayment: "Qardan Hasanah Repayment Plan",
        totalRepayment: "Total Repayment Amount",
        amilAraz: "Amil Araz",
        deptAraz: "Department Araz",
        boardAraz: "Board Araz",
    },
    totalProjectCost: "Total Project Cost",
    mozeInfo: {
        title: " Moze Information",
        moze: "Moze",
        jamiat: "Jamiat",
        gents: "Gents",
        ladies: "Ladies",
        children: "Children",
        total: "House Count",
    },
    generalInfo: {
        title: "General Information",
        name: "Project Name",
        sponser: "Executive Sponsor Name",
        category: "Project Category",
        tier1: "Tier 1",
        tier2: "Tier 2",
        owner: "Owner Department",
        stake: "Stakeholder Department",
        rank: "Project Ranking",
        umoor: "Umoor Impacted ",
        deptRating: "Department Evaluation Rating",
    },
    projectScope: {
        title: "Project Scope Statement",
        purpose: "Project Purpose and Justification",
        deliverables: "Deliverables",
        scope: "Scope Exclusions ",
        people: "People Impacted",
        site: "Site Assessment",
    },
    sourceOfFunding: {
        title: "Source Of Funding",
        amilAraz: "Amil Araz",
        deptAraz: "Department Araz",
        boardAraz: "Board Araz",
    },
    risk: {
        title: "Major Known Risks/Time Period/Photos",
        type: "Risk Type",
        knownRisk: "Major Known Risk(Including Significant Assumptions)",
        mitigation: "Mitigation",
        timePeriod: "Time Period",
        years: "Years",
        months: "Months",
    },
    button: {
        approve: "Approve",
        review: "Review",
        reject: "Reject",
        forward: "Forward to Department",
        change: "Change Request",
        changeDept: "Change Department",
        assignStake: "Assign Stakeholder",
        save: "Save",
    },
};

export const PROJECT_VIEW_BUTTONS = {
    APPROVE_BUTTON: {
        size: "large",
        className: "btn-msl submitbtn  btn-success green",
    },
    REVIEW_BUTTON: {
        name: "Review",
        size: "large",
        className: "btn-msl yellow",
    },
    REJECT_BUTTON: {
        name: "Reject",
        size: "large",
        type: "error",
        className: "btn-msl submitbtn  btn-reject red",
    },
    CHANGE_BUTTON: {
        name: "Change Request",
        size: "large",
        type: "error",
        htmlType: "submit",
        className: "btn-msl submitbtn  btn-reject red",
    },
    FORWARD_BUTTON: {
        size: (width) => `
    ${width ? "small" : "middle"};
    `,
        name: "Forward to Department",
        htmlType: "submit",
        className: "btn-3",
    },
    HOLD_BUTTON: {
        name: "Hold",
        size: (width) => `
    ${width ? "medium" : "large"};
    `,
        className: "btn-4",
    },
    UN_HOLD_BUTTON: {
        name: "Un-Hold",
        size: (width) => `
    ${width ? "medium" : "large"};
    `,
        className: "btn-4",
    },
};